import React from 'react';
import { Card } from 'antd';
import { formatCurrencyVND } from '../utils/utils';

const SystemOverviewCard = ({ totalMembers, totalSales }) => {
  return (
    <Card title="System Overview" bordered={false} style={{ width: '100%',backgroundColor: "#49ddab" }}>
      <p><strong>Total Members:</strong> {totalMembers}</p>
      <p><strong>Total Sales:</strong> {formatCurrencyVND(totalSales)}</p>
    </Card>
  );
};

export default SystemOverviewCard;
