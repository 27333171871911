// commissionCalculator.js

const conversionRate = 3500; // Tỷ lệ quy đổi PV thành tiền
const commissionRates = [
  { minPV: 10000, rate: 0.21 },
  { minPV: 8000, rate: 0.18 },
  { minPV: 6000, rate: 0.15 },
  { minPV: 4286, rate: 0.12 },
  { minPV: 2857, rate: 0.09 },
  { minPV: 1714, rate: 0.06 },
  { minPV: 429, rate: 0.03 },
];

// Hàm lấy % chiết khấu dựa trên PV
const getCommissionRate = (pv) => {
  for (let i = 0; i < commissionRates.length; i++) {
    if (pv >= commissionRates[i].minPV) {
      return commissionRates[i].rate;
    }
  }
  return 0;
};

// Hàm tính hoa hồng cho từng thành viên
const calculateCommissionForMember = (member, f1Children = [], f2Children = []) => {
  const pv = member.pv;

  // Tính tổng PV của F1 trực tiếp chưa đạt 21%
  let pvF1Not21 = 0;
  f1Children.forEach(child => {
    if (getCommissionRate(child.pv) < 0.21) {
      pvF1Not21 += child.pv;
    }
  });

  console.log(`PV F1 Not 21% for ${member.title}: ${pvF1Not21}`);

  // HH1: Tính hoa hồng từ PV cá nhân và các F1 chưa đạt 21%
  const totalPV = pv + pvF1Not21;
  const commissionRate = getCommissionRate(totalPV);
  const hh1 = totalPV * commissionRate * conversionRate;

  console.log(`Total PV for ${member.title}: ${totalPV}, Commission Rate: ${commissionRate}, HH1: ${hh1}`);

  // HH2: 3% từ hoa hồng của các F2 nếu F1 chưa đạt 21%
  let hh2 = 0;
  if (commissionRate < 0.21) {
    f2Children.forEach(f2 => {
      const f2Commission = f2.pv * getCommissionRate(f2.pv) * conversionRate;
      hh2 += f2Commission * 0.03; // 3% hoa hồng từ F2
    });
  }

  console.log(`HH2 for ${member.title}: ${hh2}`);

  // HH3: Tính hoa hồng mà các F1 chưa đạt 21% đã nhận
  let hh3 = 0;
  f1Children.forEach(f1 => {
    if (getCommissionRate(f1.pv) < 0.21) { // Chỉ tính cho F1 chưa đạt 21%
      const f1Commission = f1.pv * getCommissionRate(f1.pv) * conversionRate;
      hh3 += f1Commission; // Trừ toàn bộ hoa hồng F1 nhận
    }
  });

  console.log(`HH3 for ${member.title}: ${hh3}`);

  // HH4: 6% từ hoa hồng của F1 đã đạt 21%
  let hh4 = 0;
  f1Children.forEach(f1 => {
    if (getCommissionRate(f1.pv) === 0.21) {
      const f1Commission = f1.pv * getCommissionRate(f1.pv) * conversionRate; // Hoa hồng F1 đã nhận
      hh4 += f1Commission * 0.06; // 6% của hoa hồng F1
    }
  });

  console.log(`HH4 for ${member.title}: ${hh4}`);

  // Tổng hoa hồng
  const totalCommission = hh1 + hh2 - hh3 + hh4;

  console.log(`Total Commission for ${member.title}: ${totalCommission}`);

  return {
    hh1,
    hh2,
    hh3,
    hh4,
    totalCommission,
  };
};

// Hàm đệ quy tính hoa hồng cho toàn bộ cây
const calculateCommissionForTree = (treeData) => {
  const calculateRecursive = (member) => {
    const f1Children = member.children || [];
    const f2Children = [];
    
    // Lấy F2 từ các F1
    f1Children.forEach(f1 => {
      if (f1.children) {
        f1.children.forEach(f2 => f2Children.push(f2));
      }
    });

    // Tính hoa hồng cho member hiện tại
    const commission = calculateCommissionForMember(member, f1Children, f2Children);

    // Đệ quy tính cho từng F1
    f1Children.forEach(f1 => {
      f1.commission = calculateRecursive(f1);
    });

    return commission.totalCommission;
  };

  // Tính hoa hồng cho toàn cây
  treeData.forEach(member => {
    member.commission = calculateRecursive(member);
  });

  return treeData;
};

module.exports = {
  calculateCommissionForTree,
  calculateCommissionForMember,
};
