import React from 'react';
import { Tree } from 'antd';

const MemberTree = ({ treeData, setSelectedNodeKey, setIsModalVisible }) => {
  const onNodeRightClick = ({ node }) => {
    setSelectedNodeKey(node.key);
    setIsModalVisible(true);
  };

  return (
    <Tree
      showLine
      treeData={treeData.map(node => ({
        ...node,
        title: <span dangerouslySetInnerHTML={{ __html: node.title }} />
      }))}
      onRightClick={onNodeRightClick}
      defaultExpandAll
    />
  );
};

export default MemberTree;
