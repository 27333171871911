import { faker } from '@faker-js/faker';

export const formatCurrencyVND = (value) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value);
};

// Hàm random danh sách thành viên mới dựa vào giá sản phẩm và PV
export const generateRandomMembers = (count, productPrice, productPV, option, salesAmount) => {
  const members = [];

  for (let i = 0; i < count; i++) {
    let sales;
    let pv;

    console.log(`Option: ${option}, Sales: ${salesAmount}`); // Log option và salesAmount

    switch (option) {
      case "random21":
        // Random sales to achieve 21% commission
        // Generate a random PV between 10,000 and 20,000
        pv = Math.floor(Math.random() * (20000 - 10000) + 10000); // Random PV in range [10000, 20000)
        // Calculate sales based on the desired PV
        sales = Math.ceil((pv / productPV) * productPrice); // Doanh số = PV * (Giá sản phẩm / PV sản phẩm)
        break;

      case "sameSales":
        // Use the provided sales amount
        sales = salesAmount * productPrice; // Doanh số = số lượng bán ra * giá sản phẩm
        pv = Math.floor((sales / productPrice) * productPV); // Tính PV dựa trên doanh số
        break;

      case "randomSales":
      default:
        // Random sales and random PV
        sales = Math.floor(Math.random() * 10 + 1) * productPrice; // Random sales
        pv = Math.floor((sales / productPrice) * productPV); // Calculate PV
        break;
    }

    members.push({
      key: `F${Math.random().toString(36).substr(2, 9)}`,
      title: `${faker.name.fullName()} (${pv} PV)`, // Random tên đầy đủ bằng faker
      sales,
      pv,
      commission: 0,
    });
  }
  return members;
};